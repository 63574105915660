import "../../scss/Home/Home.scss";
import {Link} from "react-router-dom";
import HomeSignUp from "./HomeSignUp";
import React, {useEffect, useState} from "react";
import HomeLogIn from "./HomeLogIn";

function Home(props: { user: string }): React.JSX.Element {
    useEffect((): void => {
        document.title = "Home - YGO Collection";
    }, []);

    // Set the userState as a state with the initial value being the value passed in from the App.tsx.
    // Determines if the front page shows the user sign-up/log-in or information about the user's collection and decks.
    const [userState, setUserState] = useState(props.user);

    // Update userState when props.user changes.
    useEffect((): void => {
        setUserState(props.user);
    }, [props.user]);

    return (
        <div className="home-body container-fluid m-0 ms-xl-2 me-xl-3">
            <div id="description-div" className="description-div row mt-2 mt-xl-3 mb-xl-2">
                <div className="col description container-element">
                    <h1 className="description-h1">Lucina Ravenwing's Yu-Gi-Oh Collection App</h1>
                    <p className="header-description d-none d-xl-flex">This application was made to allow users to have
                        one stop for their card collections, a
                        place to build and save decks based on their collection, a wishlist of cards to buy, and pricing
                        for all of
                        these different scenarios. Allows for importing and exporting .ydke files and a TCGPlayer
                        mass entry list export.</p>
                </div>
            </div>
            <div className="row mt-xl-3">
                <div className="col-12 col-xl-3 column">
                    <div className="container-element start available-links">
                        <h1 className="d-inline-flex d-xl-block"><Link to="/build-deck">Build Deck</Link></h1>
                        <p className="link-description d-none d-xl-flex">Build a deck that displays full price of all
                            contents. Can be saved when logged in.</p>
                        <h1 className="d-inline-flex d-xl-block"><Link to="/collection">Collection</Link></h1>
                        <p className="link-description d-none d-xl-flex">Add to a collection of your owned cards.
                            Available when logged in.</p>
                        <h1 className="d-inline-flex d-xl-block"><Link to="/wishlist">Wishlist</Link></h1>
                        <p className="link-description d-none d-xl-flex">Add to a card wishlist. Available when logged
                            in.</p>
                    </div>
                </div>
                <div className="col-12 col-xl-9 column">
                    <div className={`home-log-in-div-home ${userState !== "" ? "d-none" : ""}`}>
                        <HomeLogIn/>
                    </div>
                    <div className={`home-sign-up-div-home ${userState !== "" ? "d-none" : ""}`}>
                        <HomeSignUp/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;