import "../../scss/Authorization/ResendVerification.scss";

import React, {FormEvent, useEffect, useState} from "react";
import {validateEmail} from "./SignUp";
import {NavLink} from "react-router-dom";
import {ResendVerificationRequest} from "../../models/request/ResendVerificationRequest";

function ResendVerification(): React.JSX.Element {
    useEffect((): void => {
        document.title = "Resend Verification - YGO Collection";

        // Reset the page to have the initial form and button.
        const emailForm: HTMLElement | null = document.getElementById("resend-div");
        const emailSentDiv: HTMLElement | null = document.getElementById("email-sent-div");

        if (emailForm !== null && emailSentDiv !== null) {
            emailForm.classList.remove("d-none");
            emailSentDiv.classList.add("d-none");
        }

        const resendButton: HTMLElement | null = document.getElementById("resend-button");
        const spinner: HTMLElement | null = document.getElementById("spinner-resend");

        if (resendButton !== null && spinner !== null) {
            resendButton.classList.remove("d-none");
            spinner.classList.add("d-none");
        }

    }, []);

    const [email, setEmail] = useState("");

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Show the spinner and hide the button.
        const resendButton: HTMLElement | null = document.getElementById("resend-button");
        const spinner: HTMLElement | null = document.getElementById("spinner-resend");

        if (resendButton !== null && spinner !== null) {
            resendButton.classList.add("d-none");
            spinner.classList.remove("d-none");
        }

        // Only run if the email entered is in valid email format.
        if (validateEmail(email)) {
            const url: string = "https://ygo.lucinaravenwing.net/api/v1/auth/resend-confirmation-email";

            const body: ResendVerificationRequest = {
                "EmailAddress": email
            };

            const response: Response = await fetch(url,
                {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(body)
                }
            );

            // If the response is a success or redirect.
            if (response.status < 400) {
                let emailForm: HTMLElement | null = document.getElementById("resend-div");
                const emailSentDiv: HTMLElement | null = document.getElementById("email-sent-div");

                // Reset the errors.
                if (emailForm !== null && emailSentDiv !== null) {
                    emailForm.classList.add("d-none");
                    emailSentDiv.classList.remove("d-none");
                }
            }
            // If the response is an error
            else if (response.status >= 400) {
                // Get the text response of the response to show as an error.
                const responseText: string = await response.text();

                const emailInput: HTMLElement | null = document.getElementById("email-input-rv");

                if (responseText.includes("Unable to resend email confirmation.")) {
                    const returnError: HTMLElement | null = document.getElementById("returned-error");

                    if (emailInput !== null && returnError !== null) {
                        emailInput.classList.add("error-input");
                        returnError.classList.add("error");
                    }
                }
                // Show internal server error information.
                else if (response.status >= 500) {
                    const returnError: HTMLElement | null = document.getElementById("server-error-rv");

                    if (emailInput !== null && returnError !== null) {
                        emailInput.classList.add("error-input");
                        returnError.classList.add("error");
                    }
                }
            }

            // reset the button and spinner.
            if (resendButton !== null && spinner !== null) {
                resendButton.classList.remove("d-none");

                spinner.classList.add("d-none");
            }
        }
    }

    return (
        <div>
            <div id="resend-div">
                <form onSubmit={handleSubmit}>
                    <h1>Resend Verification</h1>
                    <div className="form-element form-floating username">
                        <input type="text" value={email} id="email-input-rv" className="text-entry form-control"
                               placeholder="Email"
                               onChange={(event) => setEmail(event.target.value)}
                               onBlur={() => validateEmail(email)}/>
                        <label>Email</label>
                    </div>
                    <small id="email-error" className="error-rv">Please enter a valid email.</small>
                    <small id="server-error-rv" className="error-rv">Internal server error. Please email admin.</small>
                    <small id="returned-error" className="error-rv">Email has already been confirmed or does not exist
                        in system.</small>
                    <button type="submit" id="resend-button" className="submit-button">Resend</button>
                    <div id="spinner-resend" className="spinner-border text-dark d-none" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </form>
            </div>
            <div id="email-sent-div" className="email-sent-rv d-none">
                <p>A confirmation email has been sent. Please follow the link to confirm your email.</p>
                <div className="link-div">
                    <NavLink to="/" className="d-inline-block">Return Home</NavLink>
                </div>
                <div className="link-div">
                    <NavLink to="/log-in" className="d-inline-block">Log In</NavLink>
                </div>
            </div>
        </div>
    )
}

export default ResendVerification;