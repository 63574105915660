import React from "react";
import "../../scss/Errors/Unauthorized.scss"
import {NavLink} from "react-router-dom";

function Unauthorized(): React.JSX.Element {

    return (
        <div className="unauthorized-div">
            <h1 className="unauthorized-header">401 - Unauthorized</h1>
            <p>You do not have the proper permissions to access this web page.</p>
            <NavLink to="/log-in" className="unauthorized-link">Log In</NavLink>
            <NavLink to="/home" className="unauthorized-link">Return Home</NavLink>
        </div>
    );
}

export default Unauthorized;