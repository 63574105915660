import React, {useEffect} from "react";
import "../../scss/Misc/Profile.scss";

function Profile(): React.JSX.Element {
    useEffect((): void => {
        document.title = "Profile - YGO Collection";
    }, []);

    return (
        <div className="profile-div">
            <p>
                Profile! :o
            </p>
        </div>
    )
}

export default Profile;