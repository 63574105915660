import "../../scss/Authorization/Login.scss";

import React, {FormEvent, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {togglePasswordVisibility} from "./SignUp";
import {LogInResponse} from "../../models/response/LogInResponse";
import {LoginRequest} from "../../models/request/LoginRequest";

function Login(props: { user: string }): React.JSX.Element {
    useEffect((): void => {
        document.title = "Log In - YGO Collection";

        // Ensure the spinner isn't showing.
        toggleSpinner(false, false);
    }, []);

    // Redirect the user back to the home page if they're already logged in.
    useEffect((): void => {
        if(props.user !== "") {
            // Redirect to home page. Works with both localhost and live environments.
            window.location.href = window.location.href.replace(
                window.location.href.substring(window.location.href.lastIndexOf("/")), "/");
        }
    }, [props.user]);

    const [userNameEmail, setUserNameEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        // Turn the spinner on before log in.
        toggleSpinner(false, true);

        // check if there are any errors with the log-in attempt.
        const error: boolean = checkErrors(userNameEmail, password, false);

        // Only attempt to log-in if there are no errors.
        if (!error) {
            await attemptLogIn(userNameEmail, password);
        }

        // turn the spinner off after attempt.
        toggleSpinner(false, false);
    };

    return (
        <div className="login">
            <link rel="stylesheet" href={"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"}/>
            <form id="log-in-form" onSubmit={handleSubmit}>
                <h1>LOG IN</h1>
                <div className="form-element form-floating username">
                    <input type="text" id="username-email-input" className="text-entry form-control"
                           placeholder="Username or Email"
                           onChange={(event) => setUserNameEmail(event.target.value)}
                           value={userNameEmail}/>
                    <label>Username or Email</label>
                </div>
                <small id="username-email-error-li">Please enter a username or email.</small>
                <div id="password-div" className="form-element form-floating password">
                    <input type="password" id="password-input" className="text-entry form-control"
                           placeholder="Password"
                           onChange={(event) => setPassword(event.target.value)}
                           value={password}/>
                    <button type="button" id="password-button-li" className="eye-button"
                            onClick={() => togglePasswordVisibility(false)}>
                        <i className="fa fa-eye" id="eye-password"></i>
                    </button>
                    <label>Password</label>
                </div>
                <small id="password-error-li">Please enter a password.</small>
                <small id="entry-error-li">Username/Email or Password is incorrect.</small>
                <button type="submit" id="log-in-button-li" className="submit-button">Log In</button>
                <div id="spinner-log-in-li" className="spinner-border text-dark d-none" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div id="extra-links-li">
                    <div className="link-div link-div-li d-inline-block">
                        <NavLink to="/forgot-password">Forgot Password</NavLink>
                    </div>
                    <div className="link-div link-div-li d-inline-block">
                        <p>Don't have an account? <NavLink to="/sign-up">Sign up!</NavLink></p>
                    </div>
                </div>
            </form>
        </div>
    )
}

// Hides the button and shows a spinner.
export function toggleSpinner(isHomePage: boolean, isSpinning: boolean): void {
    const logInButton: HTMLElement | null = isHomePage ? document.getElementById("log-in-button-li-home") :
        document.getElementById("log-in-button-li");
    const spinner: HTMLElement | null = isHomePage ? document.getElementById("spinner-log-in-li-home") :
        document.getElementById("spinner-log-in-li");

    if (logInButton !== null && spinner !== null) {
        isSpinning ? logInButton.classList.add("d-none") : logInButton.classList.remove("d-none");
        isSpinning ? spinner.classList.remove("d-none") : spinner.classList.add("d-none");
    }
}

// Validates entry fields and shows errors if anything is wrong.
export function checkErrors(userNameEmail: string, password: string, isHomePage: boolean): boolean {
    // Set the correct field depending on whether it is the home page or the standalone log-in page.
    const entryError: HTMLElement | null = isHomePage ? window.document.getElementById("entry-error-li-home") :
        window.document.getElementById("entry-error-li");

    // Start by removing the previous errors.
    if (entryError !== null) {
        entryError.classList.remove("error");
    }

    let error: boolean = false;

    // Get the correct username/email and password fields based on if it's the hom page or standalone log-in.
    const userNameEmailError: HTMLElement | null = isHomePage ? document.getElementById("username-email-error-li-home") :
        document.getElementById("username-email-error-li");
    const passwordError: HTMLElement | null = isHomePage ? document.getElementById("password-error-li-home") :
        document.getElementById("password-error-li");

    // Reset the entry field errors.
    if (userNameEmailError !== null) {
        userNameEmailError.classList.remove("error");
    }
    if (passwordError !== null) {
        passwordError.classList.remove("error");
    }

    // Since this can be either a username OR a password, there isn't any actual validation here outside enforcing non-blank entries.
    // There could be a validation for character minimum, but there isn't really a reason.
    if (userNameEmail === "") {
        if (userNameEmailError !== null) {
            userNameEmailError.classList.add("error");
        }

        error = true;
    }
    if (password === "") {
        if (passwordError !== null) {
            passwordError.classList.add("error");
        }

        error = true;
    }

    // Return whether anything in the checks errored out.
    return error;
}

// Calls into the auth service to attempt to log in.
export async function attemptLogIn(userNameEmail: string, password: string): Promise<void> {
    const url: string = "https://ygo.lucinaravenwing.net/api/v1/auth/log-in";

    const body: LoginRequest = {
        UserNameEmail: userNameEmail,
        Password: password
    };

    const response: Response = await fetch(url,
        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
    );

    const logInResponse: LogInResponse = JSON.parse(await response.text());

    // Send the user to the not verified page if their account has not been verified.
    if (logInResponse.message.includes("Email has not been confirmed for this account.")) {
        // Redirect to not verified page. Works with both localhost and live environments.
        window.location.href = window.location.href.replace(
            window.location.href.substring(window.location.href.lastIndexOf("/")), "/not-verified");
    }

    // If there was a token in the response, save the information to localStorage and redirect the user to the home page.
    if (logInResponse.token !== "") {
        localStorage.setItem("token", logInResponse.token);
        localStorage.setItem("refreshToken", logInResponse.refreshToken);
        localStorage.setItem("expiresAt", logInResponse.expiresAt.toString());

        // Redirect to home page. Works with both localhost and live environments.
        window.location.href = window.location.href.replace(
            window.location.href.substring(window.location.href.lastIndexOf("/")), "/");
    } else {
        const isHomePage: boolean = window.document.title !== "Log In - YGO Collection";

        // Set the error message for the user to see. If the user info was incorrect, set the text one way, if the user
        // has attempted too many log ins, set it the other way.
        const entryError: HTMLElement | null = isHomePage ? window.document.getElementById("entry-error-li-home") :
            window.document.getElementById("entry-error-li");
        if (logInResponse.message.includes("Username, email, or password is incorrect.")) {
            if (entryError !== null) {
                entryError.classList.add("error");
                entryError.innerText = "Username/Email or Password is incorrect."
            }
        } else if (logInResponse.message.includes("User has attempted to log in too many times.")) {
            if (entryError !== null) {
                entryError.classList.add("error");
                entryError.innerText = "Too many failed log in attempts. Please try again later."
            }
        }
    }
}

export default Login;