import {TokenRefreshRequest} from "../models/request/TokenRefreshRequest";
import {LogInResponse} from "../models/response/LogInResponse";

export async function attemptTokenRefresh(): Promise<LogInResponse> {
    const blankResponse: LogInResponse = {message: "",
        token: "",
        refreshToken: "",
        expiresAt: new Date()}

    if(isJwtExpired()) {
        // Do the code for refreshing token.
        const url: string = "https://ygo.lucinaravenwing.net/api/v1/auth/token-refresh"

        const body: TokenRefreshRequest = {
            Token: localStorage.getItem("token") || "",
            RefreshToken: localStorage.getItem("refreshToken") || ""
        };

        const response: Response = await fetch(url,
            {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            }
        );

        const responseCode: number = response.status;

        if(responseCode >= 400) {
            localStorage.removeItem("expiresAt");
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
        } else {

            const logInResponse: LogInResponse = JSON.parse(await response.text());

            if(logInResponse.token !== "") {
                localStorage.setItem("token", logInResponse.token)
                localStorage.setItem("refreshToken", logInResponse.refreshToken);
                localStorage.setItem("expiresAt", logInResponse.expiresAt.toString());
            }

            return logInResponse;
        }
    }

    return blankResponse;
}

function isJwtExpired(): boolean {
    const now: string = new Date().toISOString();

    // This is getting ignored because there is a null check happening. If it is null, it's set to a string. Otherwise, it's getting set to the value.
    // @ts-ignore
    const expiresAt: string = localStorage.getItem("expiresAt") === null ? "0" : localStorage.getItem("expiresAt");

    return now.toString() > expiresAt;
}