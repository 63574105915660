import "../../scss/Home/HomeLogIn.scss"
import React, {FormEvent, useEffect, useState} from "react";
import {attemptLogIn, checkErrors, toggleSpinner} from "../Authorization/Login";

function HomeLogIn(): React.JSX.Element {
    useEffect((): void => {
        // Toggle the spinner off and the button on when the page loads.
        toggleSpinner(true, false);
    }, []);

    const [usernameEmail, setUsernameEmail] = useState("");
    const [password, setPassword] = useState("");

    // Handle the submit action. Validates, toggles spinner on and off, and attempts to log in if there are no errors.
    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        toggleSpinner(true, true);

        // Validates the form fields to ensure there are no errors.
        const error: boolean = checkErrors(usernameEmail, password, true);
        // resizes the button margin to ensure the button stays within the bounds of the container.
        resizeButtonMargin();

        if (!error) {
            await attemptLogIn(usernameEmail, password);

            // Returns the button margin to the starting value.
            resizeButtonMargin();
        }
        toggleSpinner(true, false);
    };

    // Resizes the margin of the button to ensure that it never leaves the border of the container.
    const resizeButtonMargin = (): void => {
        const logInButton: HTMLElement | null = document.getElementById("sign-up-button-li-home");
        const usernameEmailError: HTMLElement | null = document.getElementById("username-email-error-li-home");
        const passwordError: HTMLElement | null = document.getElementById("password-error-li-home");
        const genericError: HTMLElement | null = document.getElementById("entry-error-li-home");

        // Ensure none of the elements are null.
        if (usernameEmailError !== null && passwordError !== null && genericError !== null) {
            // Check if any of the errors are shown.
            const usernameEmailErrorShown: boolean = usernameEmailError.classList.contains("error");
            const passwordErrorShown: boolean = passwordError.classList.contains("error");
            const genericErrorShown: boolean = genericError.classList.contains("error");

            if (logInButton !== null) {
                // If an error is shown and the width of the window is greater than or equal to 1200, lower the top margin.
                if (window.innerWidth >= 1200 && (usernameEmailErrorShown || passwordErrorShown || genericErrorShown)) {
                    logInButton.style.marginTop = "0.5rem";
                } else {
                    logInButton.style.marginTop = "1rem";
                }
            }
        }
    }

    return (
        <div className="container-element log-in log-in-li-home">
            <link rel="stylesheet" href={"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"}/>
            <form className="li-home-form" onSubmit={handleSubmit}>
                <h1>LOG IN</h1>
                <div id="input-divs-li-home" className="input-divs-li-home top-username-div-li-home">
                    <div className="form-element-li-home username-email-form-li-home">
                        <div className="form-floating username-div-li-home">
                            <input type="text" value={usernameEmail} id="username-input-li-home"
                                   className="text-entry-li-home form-control"
                                   placeholder="Username/Email"
                                   onChange={(event) => setUsernameEmail(event.target.value)}/>
                            <label className="username-email-label-li-home">Username/Email</label>
                        </div>
                        <small id="username-email-error-li-home" className="error-info-li-home">Please enter a username
                            or email.</small>
                    </div>
                    <div className="form-element-li-home password-form-li-home">
                        <div className="form-floating password-div-li-home">
                            <input type="password" value={password} id="password-input-li-home"
                                   className="text-entry-li-home form-control"
                                   placeholder="Password"
                                   onChange={(event) => setPassword(event.target.value)}/>
                            <button type="button" id="password-button-li-home"
                                    className="eye-button password-eye-button-li-home"
                                    onClick={() => togglePasswordVisibilityLogIn()}>
                                <i className="fa fa-eye password-eye-button-li-home-i" id="eye-password-li-home"></i>
                            </button>
                            <label className="password-label-li-home">Password</label>
                        </div>
                        <small id="password-error-li-home" className="error-info-li-home">Please enter a
                            password.</small>
                    </div>
                </div>
                <small id="entry-error-li-home" className="error-info-li-home-span">Username/Email or password
                    is incorrect.</small>
                <button type="submit" id="sign-up-button-li-home" className="submit-button">Log In</button>
                <div id="spinner-log-in-li-home" className="spinner-border text-dark d-none" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </form>
        </div>
    )
}


// Changes the input type of the password fields so the user can view the entry by pressing the eye buttons.
export function togglePasswordVisibilityLogIn(): void {
    const passwordEye: HTMLElement | null = document.getElementById("eye-password-li-home");
    const passwordInput: HTMLInputElement = document.getElementById("password-input-li-home") as HTMLInputElement;

    if (passwordInput !== null) {
        if (passwordInput.type === "password") {
            passwordInput.type = "text";
        } else {
            passwordInput.type = "password";
        }
    }
    if (passwordEye !== null) {
        passwordEye.classList.contains("fa-eye")
            ? passwordEye.classList.replace("fa-eye", "fa-eye-slash")
            : passwordEye.classList.replace("fa-eye-slash", "fa-eye");
    }
}

export default HomeLogIn;