import React, {FormEvent, useEffect, useState} from "react";
import {NavLink, useSearchParams} from "react-router-dom";
import "../../scss/Authorization/ResetPassword.scss";
import {togglePasswordVisibility, validateConfirmPassword, validatePassword} from "./SignUp";
import {PasswordResetRequest} from "../../models/request/PasswordResetRequest";
import {PasswordResetResponse} from "../../models/response/PasswordResetResponse";

function ResetPassword(): React.JSX.Element {
    // Use search params to get the information to return to the auth server when sending the updated info.
    const [searchParams] = useSearchParams();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");

    useEffect((): void => {
        document.title = "Reset Password - YGO Collection";
    }, []);

    const checkErrors = (event: EventTarget & HTMLInputElement): void => {
        if(event.id === "password-input") {
            validatePassword(password);
        } else if(event.id === "confirm-password-input") {
            validateConfirmPassword(confirmPassword, password);
        }
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Reset the error to blank so that it can get overwritten.
        setError("");

        // Reset the error fields to normal.
        const generalError: HTMLElement | null = document.getElementById("reset-password-failure");

        if(generalError !== null) {
            generalError.classList.remove("error");
            generalError.classList.add("d-none");
        }

        // Start the spinner.
        const submitButton = document.getElementById("reset-password-button");
        const spinner = document.getElementById("spinner-rp");

        if (submitButton !== null && spinner !== null) {
            submitButton.classList.add("d-none");
            spinner.classList.remove("d-none");
        }

        // Only attempt to send the reset password request if the info is valid.
        if(validatePassword(password) && validateConfirmPassword(confirmPassword, password)) {
            await attemptResetPassword();
        }

        if (submitButton !== null && spinner !== null) {
            submitButton.classList.remove("d-none");

            spinner.classList.add("d-none");
        }
    }

    const attemptResetPassword = async (): Promise<void> => {
        const url: string = "https://ygo.lucinaravenwing.net/api/v1/auth/reset-password"

        const body: PasswordResetRequest = {
            Token: searchParams.get("Token") || "",
            NewPassword: password,
            Email: searchParams.get("Email") || "",
            Origin: "https://ygo.lucinaravenwing.net"
        }

        const response: Response = await fetch(url,
            {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            }
        );

        // If it's an error.
        if(response.status >= 400) {

            // Get the body to display the error to the user.
            const responseBody: string = await response.text();
            const passwordResponse: PasswordResetResponse = JSON.parse(responseBody);

            // Set the error to be the message returned by the service.
            setError(passwordResponse.message);

            // Show the general error field that will display what is in the error state variable.
            const generalError: HTMLElement | null = document.getElementById("reset-password-failure");

            if(generalError !== null) {
                generalError.classList.add("error");
                generalError.classList.remove("d-none");
            }
        } else {
            const form: HTMLElement | null = document.getElementById("reset-password-form");
            const resetDiv: HTMLElement | null = document.getElementById("email-confirmed-div");

            if(form !== null && resetDiv !== null) {
                form.classList.add("d-none");
                resetDiv.classList.remove("d-none");
            }
        }
    }

    return (
        <div id="reset-password-top-level">
            <link rel="stylesheet" href={"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"}/>
            <form id="reset-password-form" onSubmit={handleSubmit}>
                <h1>Reset Password</h1>
                <div className="form-element form-floating" id="password-div">
                    <input type="password" value={password} id="password-input" className="text-entry form-control"
                           placeholder="Password"
                           onChange={(event) => setPassword(event.target.value)}
                           onBlur={(event) => checkErrors(event.target)}/>
                    <button type="button" id="password-button" className="eye-button"
                            onClick={() => togglePasswordVisibility(false)}>
                        <i className="fa fa-eye" id="eye-password"></i>
                    </button>
                    <label>Password</label>
                </div>
                <small id="password-error">Invalid password. Passwords must contain at least: 6 characters, 1 uppercase
                    letter, 1 lowercase letter, 1 number, and 1 symbol.</small>
                <div id="confirm-password-div" className="form-element form-floating confirm-password">
                    <input type="password" value={confirmPassword} id="confirm-password-input"
                           className="text-entry form-control"
                           placeholder="test"
                           onChange={(event) => setConfirmPassword(event.target.value)}
                           onBlur={(event) => checkErrors(event.target)}/>
                    <button type="button" id="confirm-password-button" className="eye-button"
                            onClick={() => togglePasswordVisibility(true)}>
                        <i className="fa fa-eye" id="eye-confirm-password"></i>
                    </button>
                    <label>Confirm Password</label>
                </div>
                <small id="confirm-password-error" className="confirm-password-error-rp">Passwords do not match.</small>
                <small id="reset-password-failure">{error}</small>
                <button type="submit" id="reset-password-button" className="submit-button">Reset</button>
                <div id="spinner-rp" className="spinner-border text-dark d-none" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </form>
            <div id="email-confirmed-div" className="d-none">
                <p>Your password has been reset. Click on one of the following links to continue.</p>
                <div className="link-div">
                    <NavLink to="/" className="home-link-ec d-inline-block">Return Home</NavLink>
                </div>
                <div className="link-div">
                    <NavLink to="/log-in" className="log-in-link-ec d-inline-block">Log In</NavLink>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;