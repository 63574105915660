import React, {useEffect} from "react";

function BuildDeck(): React.JSX.Element {
    useEffect((): void => {
        document.title = "Deck Builder - YGO Collection";
    }, []);

    return (
        <div className="build-deck">
            <p>
                Build deck! :)
            </p>
        </div>
    )
}

export default BuildDeck;