import React, {useEffect} from "react";

function Wishlist(): React.JSX.Element {
    useEffect((): void => {
        document.title = "Wishlist - YGO Collection";
    }, []);

    return (
        <div className="wishlist">
            <p>
                Update wishlist! :]
            </p>
        </div>
    )
}

export default Wishlist;