import React from "react";
import {NavLink} from "react-router-dom";
import "../../scss/Errors/NotFound.scss"

function NotFound(): React.JSX.Element {

    return (
        <div className="not-found-div">
            <h1 className="not-found-header">404 - Not Found</h1>
            <p>This web page does not exist. Please check the URL you have entered and try again.</p>
            <NavLink to="/home" className="not-found-link">Return Home</NavLink>
        </div>
    );
}

export default NotFound;