import '../../scss/Authorization/ForgotPassword.scss';

import React, {FormEvent, useEffect, useState} from "react";
import {ForgotPasswordRequest} from "../../models/request/ForgotPasswordRequest";
import {NavLink} from "react-router-dom";

function ForgotPassword(): React.JSX.Element {
    useEffect((): void => {
        document.title = "Forgot Password - YGO Collection";
    }, []);

    // Stores either the username or email. The back end handles determining which the user entered.
    const [userNameEmail, setUserNameEmail] = useState("");

    // Handles the submit action. This will validate inputs, change the button to a spinner, show any errors,
    // and change what's displayed to the user on a successful submit.
    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        // Don't reload page.
        event.preventDefault();

        const submitButton: HTMLElement | null = document.getElementById("forgot-password-button-fp");
        const spinner: HTMLElement | null = document.getElementById("spinner-rp");

        // Hide the button and show the spinner.
        if (submitButton !== null && spinner !== null) {
            submitButton.classList.add("d-none");
            spinner.classList.remove("d-none");
        }

        // Reset any previous errors that existed before submit was pressed.
        const userNotFoundError: HTMLElement | null = document.getElementById("user-not-found-fp");
        const noDataEnteredError: HTMLElement | null = document.getElementById("username-email-error-fp");
        const internalServerError: HTMLElement | null = document.getElementById("internal-server-error-fp");

        if(userNotFoundError !== null) { userNotFoundError.classList.remove("error"); }
        if(noDataEnteredError !== null) { noDataEnteredError.classList.remove("error"); }
        if(internalServerError !== null) { internalServerError.classList.remove("error"); }

        const error: HTMLElement | null = document.getElementById("username-email-error-fp");
        const errorInput: HTMLElement | null = document.getElementById("username-email-input");

        // Validation for username/email field. If it's blank, add error to the small saying to enter a value.
        if(userNameEmail === "") {
            const error: HTMLElement | null = document.getElementById("username-email-error-fp");
            const errorInput: HTMLElement | null = document.getElementById("username-email-input");

            if(error !== null && errorInput !== null) {
                errorInput.classList.add("error-input");

                error.classList.add("error");
            }
        } else {
            // Username/email not blank, so remove the error.
            if(error !== null && errorInput !== null) {
                errorInput.classList.remove("error-input");

                error.classList.remove("error");
            }

            // call into ygo service to get forgot password email sent.
            const url: string = "https://ygo.lucinaravenwing.net/api/v1/auth/forgot-password";

            const body: ForgotPasswordRequest = {
                UsernameEmail: userNameEmail
            };

            const response: Response = await fetch(url,
                {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(body)
                }
            );

            // Response less than 400 meaning redirect or success. Set the page to display the email sent notification.
            if (response.status < 400) {
                let forgotPasswordForm: HTMLElement | null = document.getElementById("forgot-password-form");
                const emailDiv: HTMLElement | null = document.getElementById("email-sent-div");

                if (forgotPasswordForm !== null && emailDiv !== null) {
                    forgotPasswordForm.classList.add("d-none");
                    emailDiv.classList.remove("d-none");
                }
            // Response between 400 and 500, meaning user error. Display proper error to user depending on what was returned.
            } else if (response.status > 399 && response.status < 500) {
                const responseBody: string = await response.text();

                if(errorInput !== null) {
                    errorInput.classList.add("error-input");
                }

                if (responseBody.includes("User not found")) {
                    if (userNotFoundError !== null) {
                        userNotFoundError.classList.add("error");
                    }
                }
                if (responseBody.includes("Please enter a username or email")) {
                    if (noDataEnteredError !== null) {
                        noDataEnteredError.classList.add("error");
                    }
                }
            // Only thing left is 500+ errors. Display the internal error with information returned from backend.
            } else {

                if(errorInput !== null) {
                    errorInput.classList.add("error-input");
                }

                if (internalServerError !== null) {
                    internalServerError.classList.add("error");
                }
            }
        }

        // Reset to the button and hide the spinner. This only shows if there is an error.
        if (submitButton !== null && spinner !== null) {
            submitButton.classList.remove("d-none");

            spinner.classList.add("d-none");
        }
    }

    return (
        <div className="forgot-password">
            <form id="forgot-password-form" onSubmit={handleSubmit}>
                <h1>Forgot Password</h1>
                <div className="username-email-div-fp form-element form-floating">
                    <input type="text" id="username-email-input" className="text-entry form-control"
                           placeholder="Username or Email"
                           onChange={(event) => setUserNameEmail(event.target.value)}
                           value={userNameEmail}/>
                    <label>Username or Email</label>
                </div>
                <small id="username-email-error-fp">Please enter a username or email.</small>
                <small id="user-not-found-fp">User with given username or email was not found.</small>
                <small id="internal-server-error-fp">Internal server error. Please contact lucinaravenwing.net@gmail.com with information.</small>
                <button type="submit" id="forgot-password-button-fp" className="submit-button">Submit</button>
                <div id="spinner-rp" className="spinner-border text-dark d-none" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </form>
            <div id="email-sent-div" className="d-none">
                <p>A password reset email has been sent. Please follow the link to reset your password.</p>
                <div className="link-div">
                    <NavLink to="/" className="d-inline-block">Return Home</NavLink>
                </div>
                <div className="link-div">
                    <NavLink to="/log-in" className="d-inline-block">Log In</NavLink>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;