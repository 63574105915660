import '../scss/App.scss';
import {Route, Routes} from "react-router-dom";
import BuildDeck from "./Cards/BuildDeck";
import Login from "./Authorization/Login";
import SignUp from "./Authorization/SignUp";
import ForgotPassword from "./Authorization/ForgotPassword";
import NotVerified from "./LandingPages/NotVerified";
import EmailConfirmed from "./LandingPages/EmailConfirmed";
import ResetPassword from "./Authorization/ResetPassword";
import NavHeader from "./Misc/NavHeader";
import Home from "./Home/Home";
import Collection from "./Cards/Collection";
import Wishlist from "./Cards/Wishlist";
import Profile from "./Misc/Profile";
import React, {useEffect, useState} from "react";
import ResendVerification from "./Authorization/ResendVerification";
import {attemptTokenRefresh} from "../store/TokenManagement";
import NotFound from "./Errors/NotFound";
import Unauthorized from "./Errors/Unauthorized";
import InternalServerError from "./Errors/InternalServerError";

function App(): React.JSX.Element {

    // Sets the global state for the logged in status of the user.
    const [logInState, setLogInState] = useState("");

    useEffect((): void => {
        const token: string = localStorage.getItem("token") || "";

        // The token will be deleted if the user has logged out and won't exist if they never logged in.
        // This runs on page load and since we're in App, it will be on every page load.
        if(token !== "") {
            setLogInState(token);

            attemptTokenRefresh();
        }
    }, []);

    const documentHeight = (): void => {
        const doc: HTMLElement = document.documentElement;
        // Set an inner height property for use in the SASS docs.
        doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
    }

    window.addEventListener("resize", documentHeight);
    documentHeight();

    return (
        <div className={"body-div"}>
            <div className="app">
                <header className="app-header">
                    <NavHeader user={logInState}/>
                </header>
                <div className="app-body">
                    <Routes>
                        <Route path="/" element={<Home user={logInState}/>}/>
                        <Route path="/build-deck" element={<BuildDeck/>}/>
                        <Route path="/log-in" element={<Login user={logInState}/>}/>
                        <Route path="/sign-up" element={<SignUp/>}/>
                        <Route path="/collection" element={<Collection/>}/>
                        <Route path="/wishlist" element={<Wishlist/>}/>
                        <Route path="/forgot-password" element={<ForgotPassword/>}/>
                        <Route path="/not-verified" element={<NotVerified/>}/>
                        <Route path="/email-confirmed" element={<EmailConfirmed/>}/>
                        <Route path="/resend-verification" element={<ResendVerification/>}/>
                        <Route path="/reset-password" element={<ResetPassword/>}/>
                        <Route path="profile" element={<Profile/>}/>

                        <Route path="/unauthorized" element={<Unauthorized/>}/>
                        <Route path="/internal-server-error" element={<InternalServerError/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default App;
