import "../../scss/Home/HomeSignUp.scss"
import React, {FormEvent, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {
    attemptSignUp,
    togglePasswordVisibility,
    validateConfirmPassword,
    validateEmail,
    validatePassword,
    validateUsername
} from "../Authorization/SignUp";

function HomeSignUp(): React.JSX.Element {
    // Reset the view to show the form and not the email sent div.
    useEffect((): void => {
        const signUpForm: HTMLElement | null = document.getElementById("su-home-form");
        const emailSentDiv: HTMLElement | null = document.getElementById("email-sent-div");

        if (signUpForm !== null && emailSentDiv !== null) {
            signUpForm.classList.remove("d-none");
            emailSentDiv.classList.add("d-none");
        }

    }, []);

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    // Calls into the individual validations depending on what event is emitted. Uses the state variables.
    const checkErrors = (event: EventTarget & HTMLInputElement): void => {
        if (event.id === "username-input") {
            validateUsername(username);
        } else if (event.id === "email-input") {
            validateEmail(email);
        } else if (event.id === "password-input") {
            validatePassword(password);
        } else if (event.id === "confirm-password-input") {
            validateConfirmPassword(confirmPassword, password);
        }
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        // Replace the sign-up button with the spinner.
        const signUpButton: HTMLElement | null = document.getElementById("sign-up-button-su-home");
        const spinner: HTMLElement | null = document.getElementById("spinner-sign-up");

        if (signUpButton !== null && spinner !== null) {
            signUpButton.style.display = "none";
            spinner.classList.remove("d-none");
        }

        await attemptSignUp(username, email, password, confirmPassword);
        resizeButtonMargin();
        resizePasswordDiv();

        if (signUpButton !== null && spinner !== null) {
            signUpButton.style.display = "inline-block";
            spinner.classList.add("d-none");
        }
    }

    const handlePasswordError = (event: EventTarget & HTMLInputElement) => {
        checkErrors(event);
        resizeButtonMargin();
    }

    // Changes the top margin of the button so that it stays in the bounds of the container.
    const resizeButtonMargin = () => {
        const signUpButton: HTMLElement | null = document.getElementById("sign-up-button-su-home");
        const passwordError: HTMLElement | null = document.getElementById("password-error");
        const confirmPasswordError: HTMLElement | null = document.getElementById("confirm-password-error");

        if (passwordError !== null && confirmPasswordError !== null) {
            const passwordErrorShown: boolean = passwordError.classList.contains("error");
            const confirmPasswordErrorShown: boolean = confirmPasswordError.classList.contains("error");

            if (signUpButton !== null) {
                // Remove the top margin only if the window width is 1200px or greater.
                if (window.innerWidth >= 1200 && (passwordErrorShown || confirmPasswordErrorShown)) {
                    signUpButton.style.marginTop = "0";
                } else {
                    signUpButton.style.marginTop = "2rem";
                }
            }
        }
    }

    const handleEmailUsernameError = (event: EventTarget & HTMLInputElement) => {
        checkErrors(event);
        resizePasswordDiv();
    }

    // Change the password div top margin if there are too many errors being shown.
    const resizePasswordDiv = () => {
        const usernameError: HTMLElement | null = document.getElementById("username-error");
        const emailError: HTMLElement | null = document.getElementById("email-error");
        const passwordDiv: HTMLElement | null = document.getElementById("passwords-div-su-home");

        if (usernameError !== null && emailError !== null) {
            const usernameErrorShown: boolean = usernameError.classList.contains("error");
            const emailErrorShown: boolean = emailError.classList.contains("error");

            if (passwordDiv !== null) {
                if ((usernameErrorShown || emailErrorShown)) {
                    passwordDiv.style.marginTop = "0";
                } else {
                    passwordDiv.style.marginTop = "1rem";
                }
            }
        }
    }

    return (
        <div className="container-element end sign-up sign-up-home-div">
            <link rel="stylesheet" href={"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"}/>
            <form id="su-home-form" onSubmit={handleSubmit}>
                <h1>SIGN UP</h1>
                <div id="username-email-div-su-home">
                    <div className="form-element-su-home username-su-home">
                        <div className="form-floating username-su-home username-su-home-floating">
                            <input type="text" value={username} id="username-input"
                                   className="text-entry-su-home form-control"
                                   placeholder="Username"
                                   onChange={(event) => setUsername(event.target.value)}
                                   onBlur={(event) => handleEmailUsernameError(event.target)}/>
                            <label>Username</label>
                        </div>
                        <small id="username-error" className="error-info-su-home">Username must contain at least 6
                            characters
                            and can only include letters, numbers, or the following symbols: '-._+'.</small>
                        <small id="username-exists-error" className="error-info-su-home">An account with the given
                            username
                            already exists. If you have
                            forgotten your password, please <NavLink to="/forgot-password">click here</NavLink>.</small>
                    </div>
                    <div className="form-element-su-home, email-su-home">
                        <div className="form-floating email-su-home-floating">
                            <input type="email" value={email} id="email-input"
                                   className="text-entry-su-home form-control"
                                   placeholder="Email"
                                   onChange={(event) => setEmail(event.target.value)}
                                   onBlur={(event) => handleEmailUsernameError(event.target)}/>
                            <label>Email</label>
                        </div>
                        <small id="email-error" className="error-info-su-home">Please enter a valid email.</small>
                        <small id="email-exists-error" className="error-info-su-home">An account with the given email
                            already
                            exists. If you have forgotten
                            your password, please <NavLink to="/forgot-password">click here</NavLink>.</small>
                    </div>
                </div>
                <div id="passwords-div-su-home">
                    <div className="form-element-su-home password-form-su-home">
                        <div id="password-div" className="form-floating password-div-su-home">
                            <input type="password" value={password} id="password-input"
                                   className="text-entry-su-home form-control password-input-su-home"
                                   placeholder="Password"
                                   onChange={(event) => setPassword(event.target.value)}
                                   onBlur={(event) => handlePasswordError(event.target)}/>
                            <button type="button" id="password-button"
                                    className="eye-button password-eye-button-su-home"
                                    onClick={() => togglePasswordVisibility(false)}>
                                <i className="fa fa-eye password-eye-button-su-home" id="eye-password"></i>
                            </button>
                            <label className="password-label-su-home">Password</label>
                        </div>
                        <small id="password-error" className="error-info-su-home">Invalid password. Passwords must
                            contain
                            at least: 6 characters, 1
                            uppercase
                            letter, 1 lowercase letter, 1 number, and 1 symbol.</small>
                    </div>
                    <div id="confirm-password-form" className="form-element-su-home confirm-password-form-su-home">
                        <div id="confirm-password-div"
                             className="form-floating confirm-password confirm-password-div-su-home">
                            <input type="password" value={confirmPassword} id="confirm-password-input"
                                   className="text-entry-su-home form-control confirm-password-input-su-home"
                                   placeholder="test"
                                   onChange={(event) => setConfirmPassword(event.target.value)}
                                   onBlur={(event) => handlePasswordError(event.target)}/>
                            <button type="button" id="confirm-password-button"
                                    className="eye-button confirm-password-eye-su-home"
                                    onClick={() => togglePasswordVisibility(true)}>
                                <i className="fa fa-eye password-eye-button-su-home" id="eye-confirm-password"></i>
                            </button>
                            <label>Confirm Password</label>
                        </div>
                        <small id="confirm-password-error" className="error-info-su-home">Passwords do not
                            match.</small>
                    </div>
                </div>
                <button type="submit" id="sign-up-button-su-home" className="submit-button">Sign Up!</button>
                <div id="spinner-sign-up" className="spinner-border text-dark d-none" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </form>
            <div id="email-sent-div-su-home" className="email-sent-div-su-home d-none">
                <h1>Email Sent</h1>
                <p>A confirmation email has been sent. Please follow the link to confirm your email.</p>
            </div>
        </div>
    )
}

export default HomeSignUp;