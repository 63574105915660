import React, {useEffect} from "react";
import {NavLink} from "react-router-dom";
import "../../scss/LandingPages/NotVerified.scss";

function NotVerified(): React.JSX.Element {
    useEffect((): void => {
        document.title = "Not Verified - YGO Collection";
    }, []);

    return (
        <div id="not-verified-div">
            <h2>User has not been verified, please click <NavLink to={"/resend-verification"}>here</NavLink> to enter an
                email to resend verification.</h2>
        </div>
    )
}

export default NotVerified;