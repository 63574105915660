import {NavLink} from "react-router-dom";
import "../../scss/LandingPages/EmailConfirmed.scss"
import React, {useEffect} from "react";

function EmailConfirmed(): React.JSX.Element {
    useEffect((): void => {
        document.title = "Email Confirmed - YGO Collection";
    }, []);

    return (
        <div id="email-confirmed-div">
            <p>Your email has been confirmed. Click on one of the following links to continue.</p>
            <div className="link-div">
                <NavLink to="/" className="home-link-ec d-inline-block">Return Home</NavLink>
            </div>
            <div className="link-div">
                <NavLink to="/log-in" className="log-in-link-ec d-inline-block">Log In</NavLink>
            </div>
        </div>
    )
}

export default EmailConfirmed;