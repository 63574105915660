import React, {useEffect} from "react";

function Collection(): React.JSX.Element {
    useEffect((): void => {
        document.title = "Collection - YGO Collection";
    }, []);

    return (
        <div className="collection-builder">
            <p>
                Collection! (:
            </p>
        </div>
    )
}

export default Collection;