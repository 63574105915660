import '../../scss/Misc/NavHeader.scss';
import {NavLink} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ReactComponent as HamburgerIcon} from "../../misc/Hamburger_icon.svg";
import {LogOutResponse} from "../../models/response/LogOutResponse";
import {LogOutRequest} from "../../models/request/LogOutRequest";

function NavHeader(props: { user: string }): React.JSX.Element {
    const [userState, setUserState] = useState(props.user);

    useEffect((): void => {
        setUserState(props.user)
    }, [props.user]);

    // Resize the text as the screen gets smaller to keep it in the header.
    const homeTextSize = (): void => {
        const navHeader: HTMLElement | null = document.getElementById("nav-header");
        let navHeaderHeight: number = 0;

        const homeText: HTMLElement | null = document.getElementById("home-text");

        if (homeText !== null && navHeader !== null) {
            navHeaderHeight = navHeader.scrollHeight;

            // Default the text at 30px and do a variable size when it gets smaller.
            if (navHeaderHeight !== null && navHeaderHeight === 75) {
                homeText.style.setProperty("--home-text-size", "30px");
            } else {
                homeText.style.setProperty("--home-text-size", "calc(20px + 1.5vh)")
            }
        }
    }

    // Log the user out, deleting the stored token information.
    const logOut = async (): Promise<void> => {
        const url: string = "https://ygo.lucinaravenwing.net/api/v1/auth/log-out";

        // The body of the request expects the refreshToken.
        const body: LogOutRequest = {
            RefreshToken: localStorage.getItem("refreshToken") === null ? "" : localStorage.getItem("refreshToken")
        };

        const response: Response = await fetch(url,
            {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            }
        );

        // delete token information on successful log out.
        if (response.status === 200) {
            localStorage.removeItem("token")
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("expiresAt");

            setUserState("");

            // Redirect to home page. Works with both localhost and live environments.
            window.location.href = window.location.href.replace(
                window.location.href.substring(window.location.href.lastIndexOf("/")), "/");
        } else {
            const logOutResponse: LogOutResponse = JSON.parse(await response.text());

            // Alert the user of the error.
            alert(logOutResponse.message);
        }
    }

    // Use event listeners to properly set the text size on resizing.
    window.addEventListener("resize", homeTextSize);
    window.addEventListener("resize", changeSize);
    homeTextSize();

    return (
        <div className="body-div">
            <div id="nav-header" className="nav-header d-xl-container-fluid">
                <ul className="d-none d-xl-flex">
                    <NavLink to="/build-deck" className="extra-links">Decks</NavLink>
                    <NavLink to="/collection" className="extra-links">Collection</NavLink>
                    <NavLink to="/wishlist" className="extra-links">Wishlist</NavLink>
                </ul>
                <div id="links-div" className="links-div col-xl-5">
                    <NavLink to="/" id="home-text" className="home-text">YGO Collection</NavLink>
                    <ul id="links" className="d-xl-none">
                        <NavLink to="/build-deck" className="extra-links" onClick={expandMenu}>Build Deck</NavLink>
                        <NavLink to="/collection" className="extra-links" onClick={expandMenu}>Collection</NavLink>
                        <NavLink to="/wishlist" className="extra-links" onClick={expandMenu}>Wishlist</NavLink>
                        <NavLink to="/log-in" className={`extra-links log-in ${userState !== "" ? "d-none" : ""}`}
                                 onClick={expandMenu}>Log in</NavLink>
                        <NavLink to="/sign-up" id="sign-up-ham"
                                 className={`extra-links sign-up ${userState !== "" ? "d-none" : ""}`}
                                 onClick={expandMenu}>Sign
                            up</NavLink>
                        <NavLink to="/profile" id="profile-ham"
                                 className={`profile ${userState !== "" ? "" : "d-none"}`}>Profile</NavLink>
                        <NavLink to="/" id="log-out-ham" className={`log-out ${userState !== "" ? "" : "d-none"}`}
                           onClick={logOut}>Log Out</NavLink>
                    </ul>
                </div>
                <div className="ham-menu">
                    <button className="btn btn-link d-xl-none button-nav-header" onClick={expandMenu}>
                        <HamburgerIcon fill="white"></HamburgerIcon>
                    </button>
                </div>
                <ul className="user-links d-none d-xl-flex">
                    <li className={` ${userState !== "" ? "d-none" : ""}`}>
                        <NavLink to="/log-in" className="log-in-header">Log in</NavLink>
                    </li>
                    <li className={`${userState !== "" ? "d-none" : ""}`}>
                        <NavLink to="/sign-up" className="sign-up-header">Sign up</NavLink>
                    </li>
                    <li className={`${userState !== "" ? "" : "d-none"}`}>
                        <NavLink to="/profile" className="profile-header">Profile</NavLink>
                    </li>
                    <li className={`${userState !== "" ? "" : "d-none"}`}>
                        <NavLink to={"/"} id="log-out-header" className="log-out-header" onClick={logOut}>Log
                            Out</NavLink>
                    </li>
                </ul>
            </div>
        </div>
    )
}

// expand the menu when clicking on the hamburger icon.
function expandMenu(): void {
    const links: HTMLElement | null = document.getElementById("links");
    const navHeader: HTMLElement | null = document.getElementById("nav-header");

    if (links === null || navHeader === null) {
        return;
    }

    // Change the style to none if it's currently block and resize the nav header.
    if (links.style.display === "block") {
        links.style.display = "none";
        changeSize();
    } else {
        // Change the links to block if it's currently none and resize the nav header.
        links.style.display = "block";
        changeSize();
    }
}

// Change the height of the nav header depending on the size of the window.
function changeSize(): void {
    const links: HTMLElement | null = document.getElementById("links");
    const navHeader: HTMLElement | null = document.getElementById("nav-header");

    if (links === null || navHeader === null) {
        return;
    }

    // Only resize when the css changes the display of the links to block.
    if (links.style.display === "block") {
        const homeText: HTMLElement | null = document.getElementById("home-text");
        if (homeText === null) {
            return;
        }

        const testHeight: number = homeText.scrollHeight + links.scrollHeight + 35;

        navHeader.style.height = testHeight + "px";
    } else {
        navHeader.style.height = "8.9vh";
    }
}

export default NavHeader;