import React from "react";
import {NavLink} from "react-router-dom";
import "../../scss/Errors/InternalServerError.scss";

function InternalServerError(): React.JSX.Element {
    return (
        <div className="internal-server-error-div">
            <h1 className="internal-server-error-header">500 - Internal Server Error</h1>
            <p>That's on me. Please email lucinaravenwing.net@gmail.com with any information on how this error occurred.</p>
            <NavLink to="/home" className="internal-server-error-link">Return Home</NavLink>
        </div>
    );
}

export default InternalServerError;