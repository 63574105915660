import '../../scss/Authorization/SignUp.scss'
import React, {FormEvent, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {SignUpRequest} from "../../models/request/SignUpRequest";

function SignUp(): React.JSX.Element {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    useEffect((): void => {
        document.title = "Sign Up - YGO Collection";

        // Reset the page to the default view on load.
        const signUpForm: HTMLElement | null = document.getElementById("sign-up-form");
        const emailSentDiv: HTMLElement | null = document.getElementById("email-sent-div");

        if (signUpForm !== null && emailSentDiv !== null) {
            signUpForm.classList.remove("d-none");
            emailSentDiv.classList.add("d-none");
        }

        const signUpButton: HTMLElement | null = document.getElementById("sign-up-button-su");
        const spinner: HTMLElement | null = document.getElementById("spinner-sign-up");

        if (signUpButton !== null && spinner !== null) {
            signUpButton.classList.remove("d-none");
            spinner.classList.add("d-none");
        }
    }, []);

    const checkErrors = (event: EventTarget & HTMLInputElement): void => {
        if (event.id === "username-input") {
            validateUsername(username);
        } else if (event.id === "email-input") {
            validateEmail(email);
        } else if (event.id === "password-input") {
            validatePassword(password);
        } else if (event.id === "confirm-password-input") {
            validateConfirmPassword(confirmPassword, password);
        }
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        const signUpButton: HTMLElement | null = document.getElementById("sign-up-button-su");
        const spinner: HTMLElement | null = document.getElementById("spinner-sign-up");

        if (signUpButton !== null && spinner !== null) {
            signUpButton.classList.add("d-none");
            spinner.classList.remove("d-none");
        }

        await attemptSignUp(username, email, password, confirmPassword);

        if (signUpButton !== null && spinner !== null) {
            signUpButton.classList.remove("d-none");

            spinner.classList.add("d-none");
        }
    }

    return (
        <div className="sign-up-body">
            <link rel="stylesheet" href={"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"}/>
            <form id="sign-up-form" onSubmit={handleSubmit}>
                <h1>SIGN UP</h1>
                <div className="form-element form-floating username">
                    <input type="text" value={username} id="username-input" className="text-entry form-control"
                           placeholder="Username"
                           onChange={(event) => setUsername(event.target.value)}
                           onBlur={(event) => checkErrors(event.target)}/>
                    <label>Username</label>
                </div>
                <small id="username-error">Username does not meet requirements. Must contain at least 6 characters and
                    can only include letters, numbers, or the following symbols: '-._+'.</small>
                <small id="username-exists-error">An account with the given username already exists. If you have
                    forgotten your password, please <NavLink to="/forgot-password">click here</NavLink>.</small>
                <div className="form-element form-floating">
                    <input type="email" value={email} id="email-input" className="text-entry form-control"
                           placeholder="Email"
                           onChange={(event) => setEmail(event.target.value)}
                           onBlur={(event) => checkErrors(event.target)}/>
                    <label>Email</label>
                </div>
                <small id="email-error">Please enter a valid email.</small>
                <small id="email-exists-error">An account with the given email already exists. If you have forgotten
                    your password, please <NavLink to="/forgot-password">click here</NavLink>.</small>
                <div className="form-element form-floating" id="password-div">
                    <input type="password" value={password} id="password-input" className="text-entry form-control"
                           placeholder="Password"
                           onChange={(event) => setPassword(event.target.value)}
                           onBlur={(event) => checkErrors(event.target)}/>
                    <button type="button" id="password-button" className="eye-button"
                            onClick={() => togglePasswordVisibility(false)}>
                        <i className="fa fa-eye" id="eye-password"></i>
                    </button>
                    <label>Password</label>
                </div>
                <small id="password-error">Invalid password. Passwords must contain at least: 6 characters, 1 uppercase
                    letter, 1 lowercase letter, 1 number, and 1 symbol.</small>
                <div id="confirm-password-div" className="form-element form-floating confirm-password">
                    <input type="password" value={confirmPassword} id="confirm-password-input"
                           className="text-entry form-control"
                           placeholder="test"
                           onChange={(event) => setConfirmPassword(event.target.value)}
                           onBlur={(event) => checkErrors(event.target)}/>
                    <button type="button" id="confirm-password-button" className="eye-button"
                            onClick={() => togglePasswordVisibility(true)}>
                        <i className="fa fa-eye" id="eye-confirm-password"></i>
                    </button>
                    <label>Confirm Password</label>
                </div>
                <small id="confirm-password-error">Passwords do not match.</small>
                <button type="submit" id="sign-up-button-su" className="submit-button">Sign Up!</button>
                <div id="spinner-sign-up" className="spinner-border text-dark d-none" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <p id="log-in-sign-up">Already have an account? <NavLink to="/log=in">Log In!</NavLink></p>
            </form>
            <div id="email-sent-div" className="d-none">
                <p>A confirmation email has been sent. Please follow the link to confirm your email.</p>
                <div className="link-div">
                    <NavLink to="/" className="d-inline-block">Return Home</NavLink>
                </div>
                <div className="link-div">
                    <NavLink to="/log-in" className="d-inline-block">Log In</NavLink>
                </div>
            </div>
        </div>
    )
}

// Takes in a parameter denoting if the password to be toggled is the "confirm password" field or the password field.
export function togglePasswordVisibility(isConfirm: boolean): void {
    if (isConfirm) {
        const confirmEye: HTMLElement | null = document.getElementById("eye-confirm-password");
        const confirmInput: HTMLInputElement = document.getElementById("confirm-password-input") as HTMLInputElement;

        if (confirmEye !== null) {
            confirmEye.classList.contains("fa-eye")
                ? confirmEye.classList.replace("fa-eye", "fa-eye-slash")
                : confirmEye.classList.replace("fa-eye-slash", "fa-eye");
        }
        if (confirmInput !== null) {
            if (confirmInput.type === "password") {
                confirmInput.type = "text";
            } else {
                confirmInput.type = "password";
            }
        }

    } else {
        const passwordEye: HTMLElement | null = document.getElementById("eye-password");
        const passwordInput: HTMLInputElement = document.getElementById("password-input") as HTMLInputElement;

        if (passwordEye !== null) {
            passwordEye.classList.contains("fa-eye")
                ? passwordEye.classList.replace("fa-eye", "fa-eye-slash")
                : passwordEye.classList.replace("fa-eye-slash", "fa-eye");
        }
        if (passwordInput !== null) {
            if (passwordInput.type === "password") {
                passwordInput.type = "text";
            } else {
                passwordInput.type = "password";
            }
        }
    }
}

export async function attemptSignUp(username: string, email: string, password: string, confirmPassword: string): Promise<void> {
    const validUsername = validateUsername(username);
    const validEmail = validateEmail(email);
    const validPassword = validatePassword(password);
    const validConfirmPassword = validateConfirmPassword(confirmPassword, password);

    // Only attempt to sign up if everything is valid.
    if (validUsername && validEmail && validPassword && validConfirmPassword) {
        const url: string = "https://ygo.lucinaravenwing.net/api/v1/auth/sign-up";

        const body: SignUpRequest = {
            Username: username,
            EmailAddress: email,
            Password: password,
            ConfirmPassword: confirmPassword
        };

        const response: Response = await fetch(url,
            {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            }
        );

        // If the response is a success or redirect.
        if (response.status < 400) {
            let signUpForm: HTMLElement | null = document.getElementById("sign-up-form");
            const emailDiv: HTMLElement | null = document.getElementById("email-sent-div");

            // Show the email sent notification. This has multiple paths as it's used for multiple pages.
            if (signUpForm !== null && emailDiv !== null) {
                signUpForm.classList.add("d-none");
                emailDiv.classList.remove("d-none");
            } else if (signUpForm === null && emailDiv !== null) {
                signUpForm = document.getElementById("su-home-form");

                if (signUpForm !== null) {
                    signUpForm.classList.add("d-none");
                    emailDiv.classList.remove("d-none");
                }
            }
        }
        // If it has an error.
        else if (response.status >= 400) {
            // Grab the body of the response.
            const responseBody = await response.text();
            // display the proper errors based on the response.
            if (responseBody.includes("email already exists")) {
                const emailInput: HTMLElement | null = document.getElementById("email-input");
                const emailExistsError: HTMLElement | null = document.getElementById("email-exists-error");

                if (emailInput !== null && emailExistsError !== null) {
                    emailInput.classList.add("error-input");
                    emailExistsError.classList.add("error");
                }
            }
            if (responseBody.includes("username already exists")) {
                const usernameInput: HTMLElement | null = document.getElementById("username-input");
                const usernameExistsError: HTMLElement | null = document.getElementById("username-exists-error");

                if (usernameInput !== null && usernameExistsError !== null) {
                    usernameInput.classList.add("error-input");
                    usernameExistsError.classList.add("error");
                }
            }
        }
    }
}

export function validateUsername(username: string): boolean {
    const usernameExistsError: HTMLElement | null = document.getElementById("username-exists-error");

    if (usernameExistsError !== null) {
        usernameExistsError.classList.remove("error");
    }

    const usernameError: HTMLElement | null = document.getElementById("username-error");
    const inputField: HTMLElement | null = document.getElementById("username-input");

    // Check if the username is over 6 characters. The rest of the validation happens in the backend.
    if (username.length < 6) {
        if (usernameError !== null) {
            usernameError.classList.add("error");
        }
        if (inputField !== null) {
            inputField.classList.add("error-input");
        }

        return false;
    } else {
        if (usernameError !== null) {
            usernameError.classList.remove("error");
        }
        if (inputField !== null) {
            inputField.classList.remove("error-input");
        }

        return true;
    }
}

export function validateEmail(email: string): boolean {
    const emailExistsError: HTMLElement | null = document.getElementById("email-exists-error");

    if (emailExistsError !== null) {
        emailExistsError.classList.remove("error");
    }

    const emailError: HTMLElement | null = document.getElementById("email-error");
    const inputField: HTMLElement | null = document.getElementById("email-input");

    // Regex for valid email.
    if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
        if (emailError !== null) {
            emailError.classList.add("error");
        }
        if (inputField !== null) {
            inputField.classList.add("error-input");
        }

        return false;
    } else {
        if (emailError) {
            emailError.classList.remove("error");
        }
        if (inputField !== null) {
            inputField.classList.remove("error-input");
        }

        return true;
    }
}

export function validatePassword(password: string): boolean {
    const passwordError: HTMLElement | null = document.getElementById("password-error");
    const inputField: HTMLElement | null = document.getElementById("password-input");

    // Regex for the valid passwords allowed in the auth application.
    if (!(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{6,}$/.test(password))) {
        if (passwordError !== null) {
            passwordError.classList.add("error");
        }
        if (inputField !== null) {
            inputField.classList.add("error-input");
        }

        return false;
    } else {
        if (passwordError) {
            passwordError.classList.remove("error");
        }
        if (inputField !== null) {
            inputField.classList.remove("error-input");
        }

        return true;
    }
}

export function validateConfirmPassword(confirmPassword: string, password: string): boolean {
    const confirmPasswordError: HTMLElement | null = document.getElementById("confirm-password-error");
    const inputField: HTMLElement | null = document.getElementById("confirm-password-input");

    // Validate that the passwords match.
    if (confirmPassword !== password) {
        if (confirmPasswordError !== null) {
            confirmPasswordError.classList.add("error");
        }
        if (inputField !== null) {
            inputField.classList.add("error-input");
        }

        return false;
    } else {
        if (confirmPasswordError !== null) {
            confirmPasswordError.classList.remove("error");
        }
        if (inputField !== null) {
            inputField.classList.remove("error-input");
        }

        return true;
    }
}

export default SignUp;